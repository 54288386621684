.cashback,
.commission {
	font-size: 0.8rem;
	vertical-align: top;
}

.cashback {
	color: green;
}

.commission {
	color: red;
}

.items-list .form-select {
	padding: 0px;
	margin-left: -0.5rem;
	padding-left: 0.5rem;
	border: 0px solid #ced4da;
	cursor: pointer;
	background-color: transparent;
	background-image: none;
}

.items-list .form-select option {
	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.items-list .row.transaction:nth-child(odd) {
	background-color: #f7f7f7;
}

.items-list .row.transaction {
	padding: 5px 0px;
}

.cetegory-not-set {
	background-color: #f42c2c1f;
}

.list-item-menu-button {
	float: right;
	padding: 0;
	width: 0px;
	position: relative;
	top: -14px;
	left: -5px;
}

.list-item-menu-button button,
.list-item-menu-button button:hover,
.list-item-menu-button button.btn:focus,
.list-item-menu-button button.show {
	color: #716e6e;
	border-color: transparent;
	background-color: transparent;
	padding: 0px;
	height: 0px;
}

button.transparent-button,
button.transparent-button:hover,
button.transparent-button.btn:focus,
button.transparent-button.show {
	background-color: transparent;
	color: black;
	border-color: transparent;
}

button.transparent-button:after {
	display: none;
}

.filer-button {
	text-align: right;
}

.category-big-icon::before {
	font-size: 1.5rem;
	border: 1px solid #d8d8d8;
	border-radius: 50px;
	padding: 7px;
	position: relative;
	top: 1rem;
}

.hidden-reccord {
	color: red;
}

.description {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.categoriesFilter {
	border: 0px solid #ced4da;
	background-image: none;
}

.comment {
	color: grey;
}

.newReccordBtn {
	border-radius: 50px;
	float: right;
	position: sticky;
	bottom: 50px;
}