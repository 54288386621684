.category-big-icon::before {
    font-size: 1.5rem;
    border: 1px solid #d8d8d8;
    border-radius: 50px;
    padding: 7px;
    position: relative;
    top: 1rem;
}


.expensesSummaryComment {
    font-size: 10px;
    color: #9b9b9b;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}

.total-persentage .bg-info {
    background-color: rgb(123 141 145) !important;
}

.total-persentage .bg-warning {
    background-color: rgb(40 91 67) !important;
}