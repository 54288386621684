.auth-form .avatar {
	border-radius: 500px;
	height: auto;
	width: 60%;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.auth-form .links {
	margin: auto;
	width: 70px;
	display: block;
	margin-top: 20px;
}

.google-login-button {
	margin: auto;
	width: 220px;
	margin-top: 110px;
}
