.delete-button {
	vertical-align: middle;
	height: auto; 
	width: auto;
}

.planner-list {
	padding-bottom: 1rem;
}

.planner-list .list-group-item {
	scroll-margin-top: 85px;
}

.addExpenseArea {
	position: sticky;
	top: 0px;
	z-index: 1;
	text-align: center;
	background-color: white;
	padding-top: calc(var(--bs-gutter-x)* 0.5);
}

.addExpenseArea .input-group {
	padding-bottom: 10px;
}

.prevPeriodExpense {
	color: #bababa;
	font-size: 0.7rem;
}

.editExpenseBtn {
	color: grey;
	position: relative;
	top: -2px;
	cursor: pointer;
}

li {
	list-style-type: none;
}

.newExpenseBtn {
	border-radius: 50px;
	float: right;
	position: sticky;
	bottom: 50px;
}